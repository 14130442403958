import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';


@Injectable({
  providedIn: 'root',
})
export class GCMService {
  preAuthenticatedToken: string;

  constructor(
    public http: HttpClient,
    private tokenUtil: TokenUtil,
    private _snackBar: MatSnackBar,
    public cs: ConnectionService
  ) {}


  clientMap = {
    "GCM-HUBSPOT-BIGQUERY": {
      boxes: ["hubspot","bigquery"],
      templateFilters: [{
        "attribute": "template_data.categories",
        "operator": "=",
        "value": "hubspottobigquery",
        "dataType": "string",
        "filterType": "static_filter"
      }]
    }
  }


  catchErrorCallback = (err) => {
    let errMsg = `Error: ${JSON.stringify(err)}`;
    console.error(errMsg);
    throw err?.error || { error: errMsg };
  }


  async getGCMAccount(id: any) {
    console.log('Getting account...', id);

    let url = `${environment.SERVER_BASE_URL}/client-platform/gcm/account/${id}`;
    let data;
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
    );
    console.log("headers", headers)
    try {
      var res:any = await this.http.get(url, { headers }).toPromise();
      data = res.result || {};
      console.log("getGCMAccount Data", data)
    } catch (error) {
      console.error("error", error)
    }

    return data;
  }


  async execute(url: string, boxToken: string, payload?: any) {
    const token = this.tokenUtil.getStatelessToken();

    const headers = new HttpHeaders()
      .set("boxConfigToken", boxToken)
      .set("Authorization", `Bearer ${token}`)
      .set("Content-Type", "application/json");

    const promise = this.http.post(url, payload, { headers }).toPromise();

    const response = await promise.then((res: any) => {
      console.log('[EXECUTE] Result', res);
      return res;
    })
    .catch(this.catchErrorCallback);

    return response;
  }



}
