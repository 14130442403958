@if(groupFilter.length>0){
    @for(item of groupFilter; track item){
        <mat-card style="margin-top: 2%; background-color:#ebeaead0;">
            <div style="width: 6%; margin-left: 94%;">
                <button matTooltip="Remove this filter" mat-icon-button color="warn" (click)="removeFilterGroupItem(item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            <mat-form-field style="width: 20%;">
                <mat-label>Relation</mat-label>
                <select matNativeControl (change)="selectOuterCondition($event, item)">
                <option value=""></option>
                @for (option of outerCondition; track option) {
                    <option [value]="option.value"
                        [selected]="(item.operator||selectedOuterCondition) === option.value">{{ option.viewValue }}</option>
                }
                </select>
            </mat-form-field>
            <app-filter-config
                [filter]="item"
                [boxObjectAttributes]="boxObjectAttributes"
                [pageMeta]="pageMeta"
                [enableSecurity]="false"
                (selectedFilter)="saveFilterConfig($event)"
            ></app-filter-config>
        </mat-card>
    }
}

<button style="margin-top: 2%;" mat-stroked-button color="primary" (click)="addFilterGroupItem()">
    <mat-icon>add</mat-icon> Add Filter Group
</button>
